const _temp0 = require("../img/parametor/info.svg");
const _temp1 = require("../img/parametor/list.svg");
const _temp2 = require("../img/parametor/plus.svg");
const _temp3 = require("../img/parametor/refresh.svg");
const _temp4 = require("../img/parametor/reload.svg");
const _temp5 = require("../img/parametor/trash.svg");
const _temp6 = require("../img/parametor/verbose.svg");
module.exports = {
  "info": _temp0,
  "list": _temp1,
  "plus": _temp2,
  "refresh": _temp3,
  "reload": _temp4,
  "trash": _temp5,
  "verbose": _temp6
}