"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stroke = void 0;
const coord_1 = require("./coord");
class Stroke {
    /**
     * Positions should be non empty, otherwise top_left and bot_right are erroneous.
     */
    constructor(positions, color, width, index) {
        this.positions = positions;
        this.color = color;
        this.width = width;
        this.index = index;
        this.top_left = new coord_1.Coord(0, 0);
        this.bot_right = new coord_1.Coord(0, 0);
        if (positions.length > 0) {
            this.top_left = new coord_1.Coord(positions[0].x, positions[0].y);
            this.bot_right = new coord_1.Coord(positions[0].x, positions[0].y);
            for (let i = 1; i < positions.length; i++) {
                this.bot_right.x = Math.max(positions[i].x, this.bot_right.x);
                this.top_left.x = Math.min(positions[i].x, this.top_left.x);
                this.bot_right.y = Math.max(positions[i].y, this.bot_right.y);
                this.top_left.y = Math.min(positions[i].y, this.top_left.y);
            }
        }
    }
    getValue(param) {
        if (param == "color") {
            return this.color;
        }
        if (param == "width") {
            return this.width;
        }
        return undefined;
    }
    translate(shift) {
        this.top_left.translate(shift);
        this.bot_right.translate(shift);
        for (const pos of this.positions.values()) {
            pos.translate(shift);
        }
    }
    rtranslate(shift) {
        this.top_left.rtranslate(shift);
        this.bot_right.rtranslate(shift);
        for (const pos of this.positions.values()) {
            pos.rtranslate(shift);
        }
    }
}
exports.Stroke = Stroke;
