"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextZone = void 0;
class TextZone {
    constructor(pos, width, text, index) {
        this.pos = pos.copy();
        this.width = width;
        this.text = text;
        this.index = index;
    }
    getValue(param) {
        if (param == "width") {
            return this.width;
        }
        if (param == "text") {
            return this.text;
        }
        return undefined;
    }
}
exports.TextZone = TextZone;
