const _temp0 = require("../img/icons/align.svg");
const _temp1 = require("../img/icons/arc.svg");
const _temp2 = require("../img/icons/area.svg");
const _temp3 = require("../img/icons/color.svg");
const _temp4 = require("../img/icons/control_point.svg");
const _temp5 = require("../img/icons/dark_mode.svg");
const _temp6 = require("../img/icons/detector.svg");
const _temp7 = require("../img/icons/edition.svg");
const _temp8 = require("../img/icons/eraser.svg");
const _temp9 = require("../img/icons/export.svg");
const _temp10 = require("../img/icons/export_gco.svg");
const _temp11 = require("../img/icons/export_gco_old.svg");
const _temp12 = require("../img/icons/export_old.svg");
const _temp13 = require("../img/icons/export_tex.svg");
const _temp14 = require("../img/icons/export_tex_old.svg");
const _temp15 = require("../img/icons/generator.svg");
const _temp16 = require("../img/icons/generator_2.svg");
const _temp17 = require("../img/icons/grid.svg");
const _temp18 = require("../img/icons/grid_polar.svg");
const _temp19 = require("../img/icons/grid_triangular_vertical.svg");
const _temp20 = require("../img/icons/help.svg");
const _temp21 = require("../img/icons/import.svg");
const _temp22 = require("../img/icons/index.svg");
const _temp23 = require("../img/icons/index_alpha_stable.svg");
const _temp24 = require("../img/icons/index_alpha_unstable.svg");
const _temp25 = require("../img/icons/index_none.svg");
const _temp26 = require("../img/icons/index_number_stable.svg");
const _temp27 = require("../img/icons/index_number_unstable.svg");
const _temp28 = require("../img/icons/modifyer.svg");
const _temp29 = require("../img/icons/rectangle.svg");
const _temp30 = require("../img/icons/selection.svg");
const _temp31 = require("../img/icons/share.svg");
const _temp32 = require("../img/icons/stroke.svg");
const _temp33 = require("../img/icons/text.svg");
const _temp34 = require("../img/icons/triangular_grid.svg");
module.exports = {
  "align": _temp0,
  "arc": _temp1,
  "area": _temp2,
  "color": _temp3,
  "control_point": _temp4,
  "dark_mode": _temp5,
  "detector": _temp6,
  "edition": _temp7,
  "eraser": _temp8,
  "export": _temp9,
  "export_gco": _temp10,
  "export_gco_old": _temp11,
  "export_old": _temp12,
  "export_tex": _temp13,
  "export_tex_old": _temp14,
  "generator": _temp15,
  "generator_2": _temp16,
  "grid": _temp17,
  "grid_polar": _temp18,
  "grid_triangular_vertical": _temp19,
  "help": _temp20,
  "import": _temp21,
  "index": _temp22,
  "index_alpha_stable": _temp23,
  "index_alpha_unstable": _temp24,
  "index_none": _temp25,
  "index_number_stable": _temp26,
  "index_number_unstable": _temp27,
  "modifyer": _temp28,
  "rectangle": _temp29,
  "selection": _temp30,
  "share": _temp31,
  "stroke": _temp32,
  "text": _temp33,
  "triangular_grid": _temp34
}