"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rectangle = void 0;
const coord_1 = require("./coord");
class Rectangle {
    constructor(c1, c2, color, index) {
        this.c1 = c1.copy();
        this.c2 = c2.copy();
        this.color = color;
        this.index = index;
    }
    getValue(param) {
        if (param == "color") {
            return this.color;
        }
        return undefined;
    }
    top_right_corner() {
        return new coord_1.Coord(Math.max(this.c1.x, this.c2.x), Math.min(this.c1.y, this.c2.y));
    }
    bot_left_corner() {
        return new coord_1.Coord(Math.min(this.c1.x, this.c2.x), Math.max(this.c1.y, this.c2.y));
    }
    top_left_corner() {
        return new coord_1.Coord(Math.min(this.c1.x, this.c2.x), Math.min(this.c1.y, this.c2.y));
    }
    bot_right_corner() {
        return new coord_1.Coord(Math.max(this.c1.x, this.c2.x), Math.max(this.c1.y, this.c2.y));
    }
}
exports.Rectangle = Rectangle;
