"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vect = exports.middle = exports.Coord = void 0;
class Coord {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    copy_from(c) {
        this.x = c.x;
        this.y = c.y;
    }
    sub(c) {
        return new Coord(this.x - c.x, this.y - c.y);
    }
    add(c) {
        return new Coord(this.x + c.x, this.y + c.y);
    }
    copy() {
        return new Coord(this.x, this.y);
    }
    getTheta(v) {
        let angle1 = Math.atan2(this.x, this.y);
        let angle2 = Math.atan2(v.x, v.y);
        return angle2 - angle1;
    }
    norm2() {
        return Math.pow(this.x, 2) + Math.pow(this.y, 2);
    }
    getRho(v) {
        let d1 = this.norm2();
        let d2 = v.norm2();
        return Math.sqrt(d2 / d1);
    }
    normalize() {
        const norm = Math.sqrt(this.norm2());
        return new Coord(this.x / norm, this.y / norm);
    }
    rotate_quarter() {
        return new Coord(this.y, -this.x);
    }
    scale(r) {
        return new Coord(this.x * r, this.y * r);
    }
    translate(shift) {
        this.x += shift.x;
        this.y += shift.y;
    }
    rtranslate(shift) {
        this.x -= shift.x;
        this.y -= shift.y;
    }
    opposite() {
        return new Coord(-this.x, -this.y);
    }
    dist2(pos) {
        return Math.pow((this.x - pos.x), 2) + Math.pow((this.y - pos.y), 2);
    }
    is_in_rect(c1, c2) {
        return Math.min(c1.x, c2.x) <= this.x && this.x <= Math.max(c1.x, c2.x) && Math.min(c1.y, c2.y) <= this.y && this.y <= Math.max(c1.y, c2.y);
    }
    middle(c) {
        return new Coord((this.x + c.x) / 2, (this.y + c.y) / 2);
    }
    // Compute the orthogonal projection of this on the line defined
    // by a point 'point' and a non zero direction.
    orthogonal_projection(point, direction) {
        const norm = direction.norm();
        const u = new Vect(direction.x / norm, direction.y / norm);
        const v = Vect.from_coords(point, this);
        const ps = u.x * v.x + u.y * v.y;
        return new Coord(point.x + u.x * ps, point.y + u.y * ps);
    }
    vectorTo(other) {
        return Vect.from_coords(this, other);
    }
}
exports.Coord = Coord;
function middle(c1, c2) {
    return new Coord((c1.x + c2.x) / 2, (c1.y + c2.y) / 2);
}
exports.middle = middle;
class Vect {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    /**
     *
     */
    rescale(r) {
        this.x *= r;
        this.y *= r;
    }
    /**
     * UNTESTED
     * Return the dot product of this vector with another one.
     * `u.dot(v) = u.x*v.x + u.y*v.y`
     */
    dot(other) {
        return this.x * other.x + this.y * other.y;
    }
    norm() {
        return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
    }
    /**
     * `v = v*s/norm(v)`
     */
    setNorm(s) {
        const n = this.norm();
        this.x = this.x * s / n;
        this.y = this.y * s / n;
    }
    /**
     * Rotates `this` Vect.
     * @param angle is in radians (3.14 or Math.PI for an half circle)
     */
    rotate(angle) {
        const a = Math.cos(angle) * this.x - Math.sin(angle) * this.y;
        const b = Math.sin(angle) * this.x + Math.cos(angle) * this.y;
        this.x = a;
        this.y = b;
    }
    set_from(v) {
        this.x = v.x;
        this.y = v.y;
    }
    sub(v) {
        return new Vect(this.x - v.x, this.y - v.y);
    }
    translate(v) {
        this.x += v.x;
        this.y += v.y;
    }
    opposite() {
        return new Vect(-this.x, -this.y);
    }
    static from_coords(src, dest) {
        return new Vect(dest.x - src.x, dest.y - src.y);
    }
}
exports.Vect = Vect;
