"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicVertex = exports.Vertex = void 0;
class Vertex {
    constructor(index, data) {
        this.index = index;
        this.data = data;
    }
}
exports.Vertex = Vertex;
// export class BasicVertex extends Vertex<BasicVertex> {
//     clone(): BasicVertex {
//         const newVertex = new BasicVertex(this.pos.x, this.pos.y, this.weight, this.color);
//         return newVertex;
//     }
//     static default(): BasicVertex{
//         return new BasicVertex(0,0,"", "black");
//     }
// }
class BasicVertex extends Vertex {
    distTo(other) {
        return Math.sqrt(this.getPos().dist2(other.getPos()));
    }
    translate(shift) {
        this.getPos().translate(shift);
    }
    getPos() {
        return this.data.getPos();
    }
    /**
     *
     * @param c1 one corner of the rectangle
     * @param c2 the opposite one
     * @returns true if this.pos is in the rectangle
     */
    isInRectangle(c1, c2) {
        return this.data.getPos().is_in_rect(c1, c2);
    }
}
exports.BasicVertex = BasicVertex;
