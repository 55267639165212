"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicLinkData = exports.BasicVertexData = void 0;
class BasicVertexData {
    constructor(pos, weight, color) {
        this.pos = pos;
        this.weight = weight;
        this.color = color;
    }
    getValue(param) {
        if (param == "color") {
            return this.color;
        }
        if (param == "weight") {
            return this.weight;
        }
        return undefined;
    }
    getPos() {
        return this.pos;
    }
    getWeight() {
        return this.weight;
    }
    setWeight(weight) {
        this.weight = weight;
    }
}
exports.BasicVertexData = BasicVertexData;
class BasicLinkData {
    constructor(cp, weight, color) {
        this.cp = cp;
        this.weight = weight;
        this.color = color;
    }
    getValue(param) {
        if (param == "cp") {
            return this.cp;
        }
        if (param == "color") {
            return this.color;
        }
        if (param == "weight") {
            return this.weight;
        }
        return undefined;
    }
    getWeight() {
        return this.weight;
    }
    setWeight(weight) {
        this.weight = weight;
    }
}
exports.BasicLinkData = BasicLinkData;
