"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeLinks = exports.completeIntoClique = void 0;
const link_1 = require("./link");
const traits_1 = require("./traits");
function completeIntoClique(g) {
    for (const [i1, v1] of g.vertices.entries()) {
        for (const [i2, v2] of g.vertices.entries()) {
            if (i1 >= i2)
                continue;
            if (g.hasLink(i1, i2, link_1.ORIENTATION.UNDIRECTED) == false) {
                g.addLink(i1, i2, link_1.ORIENTATION.UNDIRECTED, new traits_1.BasicLinkData(undefined, "", "black"));
            }
        }
    }
}
exports.completeIntoClique = completeIntoClique;
function removeLinks(g) {
    g.links.clear();
}
exports.removeLinks = removeLinks;
