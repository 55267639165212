"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Board = void 0;
const graph_1 = require("./graph");
class Board {
    constructor() {
        this.graph = new graph_1.BasicGraph();
        this.text_zones = new Map();
        this.representations = new Map();
        this.strokes = new Map();
        this.areas = new Map();
        this.rectangles = new Map();
    }
    /**
     * @param n
     * @returns n available stroke indices
     *
     * It does not start from the max of the current stroke indices.
     */
    getNextNAvailableStrokeIndices(n) {
        let index = 0;
        const indices = new Array();
        while (indices.length < n) {
            if (this.strokes.has(index) == false) {
                indices.push(index);
            }
            index += 1;
        }
        return indices;
    }
    /**
    * @param n
    * @returns n available stroke indices
    *
    * It does not start from the max of the current stroke indices.
    */
    getNextNAvailableRectangleIndices(n) {
        let index = 0;
        const indices = new Array();
        while (indices.length < n) {
            if (this.rectangles.has(index) == false) {
                indices.push(index);
            }
            index += 1;
        }
        return indices;
    }
    /**
     * @param n
     * @returns n available stroke indices
     *
     * It does not start from the max of the current stroke indices.
     */
    getNextNAvailableTextZoneIndices(n) {
        let index = 0;
        const indices = new Array();
        while (indices.length < n) {
            if (this.text_zones.has(index) == false) {
                indices.push(index);
            }
            index += 1;
        }
        return indices;
    }
    get_next_available_index_representation() {
        let index = 0;
        while (this.representations.has(index)) {
            index += 1;
        }
        return index;
    }
    get_next_available_index_text_zone() {
        let index = 0;
        while (this.text_zones.has(index)) {
            index += 1;
        }
        return index;
    }
    get_next_available_index_strokes() {
        let index = 0;
        while (this.strokes.has(index)) {
            index += 1;
        }
        return index;
    }
    get_next_available_index_area() {
        let index = 0;
        while (this.areas.has(index)) {
            index += 1;
        }
        return index;
    }
    get_next_available_index_rectangle() {
        let index = 0;
        while (this.rectangles.has(index)) {
            index += 1;
        }
        return index;
    }
    /**
     * Return an element of a certain kind and index.
     * @warning for vertices and links it returns data of these elements not all the data
     * @todo an error should be triggered if kind does not exists or index
     */
    getElement(kind, index) {
        if (kind == "TextZone") {
            const elt = this.text_zones.get(index);
            if (typeof elt == "undefined") {
                return undefined;
            }
            else {
                return elt;
            }
        }
        else if (kind == "Vertex") {
            const elt = this.graph.vertices.get(index);
            if (typeof elt == "undefined") {
                return undefined;
            }
            else {
                return elt.data;
            }
        }
        else if (kind == "Link") {
            const elt = this.graph.links.get(index);
            if (typeof elt == "undefined") {
                return undefined;
            }
            else {
                return elt.data;
            }
        }
        else if (kind == "Stroke") {
            const elt = this.strokes.get(index);
            if (typeof elt == "undefined") {
                return undefined;
            }
            else {
                return elt;
            }
        }
        else if (kind == "Area") {
            const elt = this.areas.get(index);
            if (typeof elt == "undefined") {
                return undefined;
            }
            else {
                return elt;
            }
        }
        else if (kind == "Rectangle") {
            const elt = this.rectangles.get(index);
            if (typeof elt == "undefined") {
                return undefined;
            }
            else {
                return elt;
            }
        }
        else {
            return undefined;
        }
    }
    /**
     * Return the value of a param of an element of a certain kind and index.
     * @todo return type should exist
     */
    get_value(kind, index, param) {
        const elt = this.getElement(kind, index);
        if (typeof elt == "undefined") {
            return undefined;
        }
        else {
            return elt.getValue(param);
        }
    }
    delete_stroke(stroke_index) {
        this.strokes.delete(stroke_index);
    }
    delete_area(area_index) {
        this.areas.delete(area_index);
    }
    translate_areas(indices, shift) {
        const contained_vertices = new Set();
        for (const area_index of indices.values()) {
            const area = this.areas.get(area_index);
            if (typeof area != "undefined") {
                for (const [vertex_index, vertex] of this.graph.vertices.entries()) {
                    if (area.is_containing(vertex)) {
                        contained_vertices.add(vertex_index);
                    }
                }
                area.translate(shift);
            }
        }
        this.graph.translateVertices(contained_vertices, shift);
    }
    get_subgraph_from_area(area_index) {
        const area = this.areas.get(area_index);
        const subgraph = new graph_1.Graph();
        if (typeof area == "undefined")
            return subgraph;
        for (const [index, v] of this.graph.vertices.entries()) {
            if (area.is_containing(v)) {
                subgraph.vertices.set(index, v);
            }
        }
        for (const [index, link] of this.graph.links.entries()) {
            const u = link.startVertex;
            const v = link.endVertex;
            if (area.is_containing(u) && area.is_containing(v)) {
                subgraph.links.set(index, link);
            }
        }
        return subgraph;
    }
}
exports.Board = Board;
