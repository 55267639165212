"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Area = void 0;
const coord_1 = require("./coord");
// c1 and c2 are any points in the plane (not necessarily the bottom left corner or other corners)
class Area {
    constructor(label, c1, c2, color, index) {
        this.c1 = c1;
        this.c2 = c2;
        this.label = label;
        this.color = "#E60007";
        this.index = index;
    }
    getValue(param) {
        if (param == "color") {
            return this.color;
        }
        return undefined;
    }
    translate(shift) {
        this.c1.translate(shift);
        this.c2.translate(shift);
    }
    rtranslate(shift) {
        this.c1.rtranslate(shift);
        this.c2.rtranslate(shift);
    }
    is_containing(v) {
        return Math.min(this.c1.x, this.c2.x) <= v.data.getPos().x && v.data.getPos().x <= Math.max(this.c1.x, this.c2.x) && Math.min(this.c1.y, this.c2.y) <= v.data.getPos().y && v.data.getPos().y <= Math.max(this.c1.y, this.c2.y);
    }
    top_right_corner() {
        return new coord_1.Coord(Math.max(this.c1.x, this.c2.x), Math.min(this.c1.y, this.c2.y));
    }
    bot_left_corner() {
        return new coord_1.Coord(Math.min(this.c1.x, this.c2.x), Math.max(this.c1.y, this.c2.y));
    }
    top_left_corner() {
        return new coord_1.Coord(Math.min(this.c1.x, this.c2.x), Math.min(this.c1.y, this.c2.y));
    }
    bot_right_corner() {
        return new coord_1.Coord(Math.max(this.c1.x, this.c2.x), Math.max(this.c1.y, this.c2.y));
    }
}
exports.Area = Area;
